import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SecurityLink} from './security-link';
import {ApiService} from '../../../shared/service/api.service';
import {SecurityLinkPageItem} from './security-link-page-item';

@Injectable()
export class SecurityLinkService extends ApiService<SecurityLinkPageItem, SecurityLink> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'security_link';
    }
}
