import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../shared/service/api.service';
import {WebsitePageItem} from './website-page-item';
import {Website} from './website';

@Injectable()
export class WebsiteService extends ApiService<WebsitePageItem, Website>{

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'website';
    }
}
