import {Injectable} from '@angular/core';
import {ApiService} from '../../../shared/service/api.service';
import {PostbackLogPageItem} from './postback-log-page-item';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {PostbackLogFilterParam} from './menu-item/postback-log-menu-item.component';
import {PostbackLogData} from './postback-log';

@Injectable()
export class PostbackLogService extends ApiService<PostbackLogPageItem, PostbackLogData> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public postbackLogFilterPaginated<T>(filterParams: PostbackLogFilterParam): Observable<T> {
        const params = this.getHttpParamsFromFilter(filterParams);
        return this.httpClient.get<T>(`${environment.API_ROOT_PATH}postback_filter_paginated`, {params});
    }

    public resendPostback(id: number): Observable<any> {
        return this.httpClient.post(`${environment.API_ROOT_PATH}postback_repeat/${id}`, {});
    }

    protected resourcePath(): string {
        return 'postback';
    }
}
