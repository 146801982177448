import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SecurityContext} from './security-context';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    constructor(private securityContext: SecurityContext) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authData = this.securityContext.getAccessToken();
        if (authData) {
            req = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + authData
                }
            });
        }
        return next.handle(req);
    }
}
