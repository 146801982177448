import {Injectable} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ClickPageItem} from './click-page-item';
import {ClickData} from './click';
import {Observable} from 'rxjs';
import {ClickUniqueFields} from './click-unique-fields';
import {ClickFilterParam} from './menu-item/click-menu-item.component';
import {environment} from '../../../environments/environment';
import {PaginatedRecord} from '../../shared/menu-item/abstract-filter-menu-item.component';

@Injectable()
export class ClickService extends ApiService<ClickPageItem, ClickData> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public clickFilterPaginated(filterParams: ClickFilterParam): Observable<PaginatedRecord<ClickData>> {
        const params = this.replaceIpWithSubnet(this.getHttpParamsFromFilter(filterParams));
        return this.httpClient.get<PaginatedRecord<ClickData>>(`${environment.API_ROOT_PATH}clicks_filter_paginated`, {params});
    }

    public clickFilterParameters(filterParams: ClickFilterParam): Observable<ClickUniqueFields>{
        const params = new HttpParams()
            .set('date_from', filterParams.date_from)
            .set('date_to', filterParams.date_to)
        return this.httpClient.get<ClickUniqueFields>(`${environment.API_ROOT_PATH}clicks_fields_light`, {params});
    }

    public downloadClickReport(filterParams: ClickFilterParam) {
        const params = this.replaceIpWithSubnet(this.getHttpParamsFromFilter(filterParams));
        return this.httpClient.get(`${environment.API_ROOT_PATH}clicks_filter_paginated_download`, {params, responseType: 'blob'});
    }

    protected resourcePath(): string {
        return 'click';
    }

    private replaceIpWithSubnet(params: HttpParams): HttpParams {
        const paramValue = params.get('ip');
        if (paramValue && paramValue.includes('/')) {
            params = params.append('subnet', paramValue);
            params = params.delete('ip');
        }
        return params;
    }
}
