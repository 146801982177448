import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PostbackRoutingLog } from './postback-routing-log';
import { PostbackRoutingLogPageItem } from './postback-routing-log-page-item';
import { ApiService } from '../../../shared/service/api.service';
import { FilterParam } from '../../../shared/menu-item/filter-param';
import { PaginatedRecord } from '../../../shared/menu-item/abstract-filter-menu-item.component';

@Injectable()
export class PostbackRoutingLogService extends ApiService<PostbackRoutingLogPageItem, PostbackRoutingLog> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    postbackRoutingLogFilterPaginated(filterParam: FilterParam): Observable<PaginatedRecord<PostbackRoutingLog>> {
        const params = this.getHttpParamsFromFilter(filterParam);
        return this.httpClient.get<PaginatedRecord<PostbackRoutingLog>>(`${environment.API_ROOT_PATH}postback_routing_filter_paginated`, {
            params,
        });
    }

    protected resourcePath(): string {
        return 'postback_routing_log';
    }
}
