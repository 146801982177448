import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Campaign, CampaignPageItem} from './campaign';
import {ApiService} from '../../shared/service/api.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CampaignOffer} from './campaign-offer';

@Injectable()
export class CampaignService extends ApiService<CampaignPageItem, Campaign> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public campaignOffers(): Observable<CampaignOffer[]> {
        return this.httpClient.get<CampaignOffer[]>(`${environment.API_ROOT_PATH}campaign_offer_route_offers`, {});
    }

    public updateCampaignOfferActiveStatus(campaignId, offerId, isActive): Observable<any> {
        return this.httpClient.patch<any>(`${environment.API_ROOT_PATH}campaign_offer_activation/${campaignId}/${offerId}/${isActive}`, {});
    }

    protected resourcePath(): string {
        return 'campaign_offer_route';
    }
}
