import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Role } from './role';
import { ApiService } from '../../../shared/service/api.service';
import { RolePageItem } from './role-page-item';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class RoleService extends ApiService<RolePageItem, Role> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'roles';
    }

    public loadWithUserRoles(): Observable<Role[]> {
        const params = new HttpParams().set('with_user_roles', true);
        return this.httpClient.get<Role[]>(this.getResourceUrl(), { params });
    }

    public cloneRole(id: number): Observable<any> {
        return this.httpClient.put(`${this.getResourceUrl()}/clone/${id}`, {});
    }
}
