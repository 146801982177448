import {Injectable} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {IdBlackListPageItem} from './id-black-list-page-item';
import {IdBlackList} from './id-black-list';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IdBlackListFilterParam} from './menu-item/id-black-list-menu-item.component';
import {PaginatedRecord} from '../../shared/menu-item/abstract-filter-menu-item.component';
import {environment} from '../../../environments/environment';

@Injectable()
export class IdBlackListService extends ApiService<IdBlackListPageItem, IdBlackList> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public idBlackListFilterPaginated(filterParam: IdBlackListFilterParam): Observable<PaginatedRecord<IdBlackList>> {
        const params = this.getHttpParamsFromFilter(filterParam);
        const advIdForUrl = this.getParamForUrl(filterParam, 'adv_id');
        return this.httpClient.get<PaginatedRecord<IdBlackList>>(`${environment.API_ROOT_PATH}id_black_list_paginated${advIdForUrl}`, {params});
    }

    protected resourcePath(): string {
        return 'id_black_list';
    }

    private getParamForUrl(filterParam: IdBlackListFilterParam, param: string): string {
        return filterParam[param] ? `/${filterParam[param]}` : '';
    }
}
