import {SecurityContext} from './security-context';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class PermissionsManager {

    constructor(private securityContext: SecurityContext) {
    }

    getResourcePermissionsList(resource: string): number[] {
        const permissionsValue = this.getPermissionsValue(resource);
        if (permissionsValue) {
            return this.getPermissionsList(permissionsValue);
        }
        return [];
    }

    getPermissionsValue(resource: string) {
        const permissions = this.securityContext.getPermissions();
        const menuItemPermission = permissions.find(permission => permission.uri === resource);
        return menuItemPermission?.permissions;
    }

    private getPermissionsList(permissionsValue: number) {
        const permissionsList = [];
        let currentPermission = 1;
        const binaryPermissionsList = permissionsValue.toString(2).split('').reverse();
        binaryPermissionsList.forEach(binaryPermission => {
            if (Number(binaryPermission) * currentPermission > 0) {
                permissionsList.push(currentPermission);
            }
            currentPermission *= 2;
        });

        return permissionsList;
    }
}
