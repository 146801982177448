import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PushSet, PushSetPageItem} from './push-set';
import {ApiService} from '../../../shared/service/api.service';

@Injectable()
export class PushSetService extends ApiService<PushSetPageItem, PushSet> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'push_set';
    }
}
