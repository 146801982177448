import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
// TODO: remove all methods except 'getApplicationVersion' and rename to 'VersionService'
export class CommonService {

    constructor(private httpClient: HttpClient) {
    }

    public getAllTabData<T>(url: string): Observable<T> {
        return this.httpClient.get<T>(`${environment.API_ROOT_PATH}${url}`, {});
    }

    public getApplicationVersion(): Observable<string> {
        return this.httpClient.get<string>(`${environment.API_ROOT_PATH}version`, {});
    }
}
