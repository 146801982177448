import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PushScheduleTemplate} from './push-schedule-template';
import {PushScheduleTemplatePageItem} from './push-schedule-template-page-item';
import {ApiService} from '../../../shared/service/api.service';

@Injectable()
export class PushScheduleTemplateService extends ApiService<PushScheduleTemplatePageItem, PushScheduleTemplate> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'push_schedule_template';
    }
}
