import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PushTemplate} from './push-template';
import {PushTemplatePageItem} from './push-template-page-item';
import {ApiService} from '../../../shared/service/api.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable()
export class PushTemplateService extends ApiService<PushTemplatePageItem, PushTemplate> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public update(to: PushTemplate): Observable<any> {
        return this.httpClient.patch(`${environment.API_ROOT_PATH}push_template_update/${to.id}`, to);
    }

    protected resourcePath(): string {
        return 'push_template';
    }
}
