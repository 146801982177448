import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DirectOfferUrl, DirectOfferUrlParam, Offer} from './offer';
import {ApiService} from '../../shared/service/api.service';
import {OfferPageItem} from './offer-page-item';
import {environment} from '../../../environments/environment';
import {DomainUpdateRequest} from './domain-dialog/domain';

@Injectable()
export class OfferService extends ApiService<OfferPageItem, Offer> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public changeDomain(body: DomainUpdateRequest): Observable<any> {
        return this.httpClient.patch<any>(`${environment.API_ROOT_PATH}campaign_offer_domain`, body);
    }

    public getDirectUrl(id: number, directOfferUrlParam: DirectOfferUrlParam): Observable<DirectOfferUrl> {
        const params = this.getHttpParamsFromFilter(directOfferUrlParam);
        return this.httpClient.get<DirectOfferUrl>(`${environment.API_ROOT_PATH}direct_offer_url/${id}`, {params});
    }

    public cloneOffer(id: number): Observable<any> {
        return this.httpClient.post(`${this.getResourceUrl()}/clone/${id}`, {});
    }

    protected resourcePath(): string {
        return 'offer';
    }
}
