import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'convertStatus'})
export class StatusPipe implements PipeTransform {

    transform(value: boolean, ...args: any[]): any {
        if (args.length) {
            return value ? args[0] : args[1];
        }
        const description = value ? 'YES' : 'NO';
        return description ? description : status;
    }

}
