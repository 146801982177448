import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Push} from './push';
import {PushPageItem} from './push-page-item';
import {ApiService} from '../../../shared/service/api.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable()
export class PushService extends ApiService<PushPageItem, Push> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public create(to: Push): Observable<any> {
        return this.httpClient.put(`${environment.API_ROOT_PATH}push_route`, to);
    }

    protected resourcePath(): string {
        return 'push';
    }
}
