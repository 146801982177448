import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BlockUiService} from '../shared/service/block.ui.service';
import {SecurityContext} from './security-context';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private blockUiService: BlockUiService, private securityContext: SecurityContext) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.securityContext.clear();
                    this.router.navigateByUrl('/login');
                }
                if (error.status === 403) {
                    this.router.navigateByUrl('/access-denied');
                    this.blockUiService.stopBlock();
                }
                return throwError(error);
            }));
    }
}
