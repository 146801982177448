import { Injectable } from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {UserAgentBanPageItem} from './user-agent-ban-page-item';
import {UserAgentBan} from './user-agent-ban';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserAgentBanService extends ApiService<UserAgentBanPageItem, UserAgentBan>{

  constructor(httpClient: HttpClient) {
      super(httpClient);
  }

  protected resourcePath(): string {
      return 'user_agent_ban';
  }
}
