import {Injectable} from '@angular/core';
import {ApiService} from '../../../shared/service/api.service';
import {AccessLogPageItem} from './access-log-page-item';
import {AccessLogData} from './access-log';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {AccessLogFilterParam} from './menu-item/access-log-menu-item.component';
import {PaginatedRecord} from '../../../shared/menu-item/abstract-filter-menu-item.component';

@Injectable()
export class AccessLogService extends ApiService<AccessLogPageItem, AccessLogData> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public accessLogFilterPaginated(filterParams: AccessLogFilterParam): Observable<PaginatedRecord<AccessLogData>> {
        const params = this.getHttpParamsFromFilter(filterParams);
        return this.httpClient.get<PaginatedRecord<AccessLogData>>(`${environment.API_ROOT_PATH}access_log_filter_paginated`, {params});
    }

    public rollbackAccessLog(id: number): Observable<any> {
        return this.httpClient.post(`${environment.API_ROOT_PATH}access_log_rollback/${id}`, {});
    }

    protected resourcePath(): string {
        return 'access_log';
    }
}
