<div class="exception-body">
    <div class="exception-wrapper access">
        <img src="../../../../assets/layout/images/access.png" alt="omega-layout"/>

        <p class="message-title">Access Denied!</p>
        <p class="message-summary">Please contact system administrator</p>

        <a href="/">
            <i class="pi pi-home"></i>
            <span>Home</span>
        </a>

        <div class="login-footer">PrimeNG Omega</div>
    </div>
</div>
