import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Setting, SettingsPageItem, SettingUpdateItem} from '../../shared/component/setting-item/setting';
import {DirectOfferOrigins} from '../offer/offer';

@Injectable({
    providedIn: 'root'
})
export class SettingService {

    constructor(private httpClient: HttpClient) {
    }

    loadSettings(): Observable<SettingsPageItem[]> {
        return this.httpClient.get<SettingsPageItem[]>(`${environment.API_ROOT_PATH}settings`);
    }

    loadDirectOfferOrigins(): Observable<DirectOfferOrigins> {
        return this.httpClient.get<DirectOfferOrigins>(`${environment.API_ROOT_PATH}direct_offer_origins`);

    }

    updateSetting(setting: Setting): Observable<any> {
        const settingForUpdate: SettingUpdateItem = {
            setting_description: setting.setting_description,
            setting_name: setting.setting_name,
            setting_value: setting.setting_value
        };
        return this.httpClient.patch<any>(`${environment.API_ROOT_PATH}settings/${setting.id}`, settingForUpdate);
    }
}
