import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AccessDeniedComponent} from './shared/component/access-denied/access-denied.component';
import {AnonymousGuardService} from './mt-security/anonymous.guard.service';
import {AuthGuardService} from './mt-security/auth.guard.service';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'login',
                loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
                canActivate: [AnonymousGuardService]
            },
            {
                path: '',
                loadChildren: () => import('./console/console.module').then((m) => m.ConsoleModule),
                canActivate: [AuthGuardService]
            },
            {path: 'access', component: AccessDeniedComponent},
            {
                path: '**',
                redirectTo: '',
                canActivate: [AuthGuardService]
            },
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
