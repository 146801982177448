import {PermissionType} from './permission-type';
import {AFFILIATE_RESOURCES, Resources} from './resources';
import {Injectable} from '@angular/core';
import {PermissionsManager} from './permissions-manager';
import {SecurityContext} from './security-context';

@Injectable()
export class ResourceMenuViewAccessDecisionManager {

    constructor(private permissionsManager: PermissionsManager, private securityContext: SecurityContext) {}

    hasAccessToShow(resource: Resources): boolean {
        const permissionsList = this.permissionsManager.getResourcePermissionsList(resource);
        const isAffiliate = this.securityContext.getAffiliateStatus();
        if (!!permissionsList.find(permission => permission === PermissionType.HIDE)){
            return false;
        }
        if (isAffiliate && !AFFILIATE_RESOURCES.includes(resource)) {
            return false;
        }
        if (resource === Resources.DASHBOARD) {
            return true;
        }
        return !!permissionsList.find(permission => permission === PermissionType.VIEW_ALL || PermissionType.READ);
    }
}
