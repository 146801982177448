export enum RepositoryKey {
    USERNAME = 'username',
    PERMISSIONS = 'permissions',
    FIRST_LOGIN = 'first_login',
    AFFILIATE_STATUS = 'affiliate_status',
    TOKEN = 'token',
    APPLICATION_SHOW_BANNED_APPS = 'application_show_banned_app',
    WEBSITE_SHOW_BANNED_WEBSITES = 'website_show_banned_websites',
    CLICK_SHOW_WITHOUT_APP = 'click_show_without_app',
    WEB_CLICK_SHOW_WITHOUT_WEBSITE = 'web_click_show_without_website',
    PWA_CLICK_SHOW_WITHOUT_PWA = 'pwa_click_show_without_pwa',
    EVENT_SHOW_WITHOUT_APP = 'event_show_without_app',
    WEB_EVENT_SHOW_WITHOUT_WEBSITE = 'web_event_show_without_website',
    PWA_EVENT_SHOW_WITHOUT_PWA = 'pwa_event_show_without_pwa',
    OFFER_SELECTED_GEO = 'offer_selected_geo',
    SPOOF_MODE = 'spoof_mode',
    SPOOF_USER = 'spoof_user',
    CAN_SPOOF = 'can_spoof',
    OBJ_ID = 'obj_id',
    OBJ_INFO = 'obj_info',
    DATE_FILTER = 'date_filter'
}
