import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Application, ApplicationGenerateFtdItem, Platform} from './application';
import {ApiService} from '../../shared/service/api.service';
import {ApplicationPageItem} from './application-page-item';
import {environment} from '../../../environments/environment';

@Injectable()
export class ApplicationService extends ApiService<ApplicationPageItem, Application> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public loadPlatforms(): Observable<Platform[]> {
        return this.httpClient.get<Platform[]>(`${environment.API_ROOT_PATH}platform`, {});
    }

    generateTestFtd(generateTestFtdItem: ApplicationGenerateFtdItem) {
        return this.httpClient.post(`${environment.API_ROOT_PATH}generate_test_ftd`, generateTestFtdItem);
    }

    protected resourcePath(): string {
        return 'application';
    }
}
