import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IdentifiedEntity} from '../identified-entity';

export abstract class ApiService<TPage, T extends IdentifiedEntity> {

    protected constructor(protected httpClient: HttpClient) {
    }

    public page(withDeleted?: boolean): Observable<TPage[]> {
        const params = withDeleted ? new HttpParams().set('with_deleted', withDeleted) : null;
        return this.httpClient.get<TPage[]>(this.getResourceUrl(), {params});
    }

    public load(id: number | string): Observable<T> {
        return this.httpClient.get<T>(this.getResourceUrl() + '/' + id, {});
    }

    public loadAdditional(route: string): Observable<any> {
        return this.httpClient.get(`${environment.API_ROOT_PATH}${route}`, {});
    }

    public create(to: T): Observable<any> {
        return this.httpClient.put(`${environment.API_ROOT_PATH}${this.resourcePath()}`, to);
    }

    public update(to: T): Observable<any> {
        return this.httpClient.patch(`${environment.API_ROOT_PATH}${this.resourcePath()}/${to.id}`, to);
    }

    public remove(id: number): Observable<{}> {
        const url = this.resourcePath() + `/${id}`;
        return this.httpClient.delete(this.getRootPath() + url, {});
    }

    public isNew(to: T): boolean {
        return to.id == null;
    }

    protected getRootPath(): string {
        return `${environment.API_ROOT_PATH}`;
    }

    protected getResourceUrl(): string {
        return this.getRootPath() + this.resourcePath();
    }

    protected abstract resourcePath(): string;

    protected getHttpParamsFromFilter(objectParams: object) {
        let params = new HttpParams();
        for (const param of Object.keys(objectParams)) {
            const paramValue = Array.isArray(objectParams[param]) ? objectParams[param].join(',') : objectParams[param];
            params = !!paramValue ? params.set(param, paramValue) : params;
        }

        return params;
    }
}
