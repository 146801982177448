import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ResourceMenuViewAccessDecisionManager} from './resource-menu-view-access-decision-manager';
import {RouteNameToResourceConverter} from './route-name-to-resource-converter';

@Injectable()
export class AccessGuardService implements CanActivate {

    constructor(private readonly resourceMenuViewAccessDecisionManager: ResourceMenuViewAccessDecisionManager,
                private routeNameToResourceConverter: RouteNameToResourceConverter) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const resourceName = this.routeNameToResourceConverter.convert(route.url[0].path);
        return this.resourceMenuViewAccessDecisionManager.hasAccessToShow(resourceName);
    }

}
