import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AccessControlList} from './access-control-list';
import {ApiService} from '../../../shared/service/api.service';
import {AccessControlListPageItem} from './access-control-list-page-item';

@Injectable()
export class AccessControlListService extends ApiService<AccessControlListPageItem, AccessControlList> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'acl';
    }
}
