import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'datePipe' })
export class DatePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return '';
        const date = new Date(value + 'Z');
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const userDateTimeFormat = new Intl.DateTimeFormat('ru-RU', {
            timeZone: userTimeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
        return userDateTimeFormat.format(date);
    }
}
