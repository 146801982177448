import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ChangePassword} from '../shared/component/change-user-password/change-password';

@Injectable()
export class AuthenticationService {

    constructor(private httpClient: HttpClient) {
    }

    changePassword(password: ChangePassword) {
        return this.httpClient.patch(`${environment.API_ROOT_PATH}user/password`, password);
    }

    login(username: string, password: string) {
        const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        return this.httpClient.post(environment.API_ROOT_PATH + 'auth/token', `username=${username}&password=${password}`, {headers});
    }

}
