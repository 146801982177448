import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PostbackRouteRulePageItem} from './postback-route-rule-page-item';
import {PostbackRouteRule} from './postback-route-rule';
import {ApiService} from '../../../shared/service/api.service';

@Injectable()
export class PostbackRouteRuleService extends ApiService<PostbackRouteRulePageItem, PostbackRouteRule> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'postback_route_rule';
    }
}
