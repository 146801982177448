import {ApplicationRepository} from '../mt-repository/application-repository';
import {RepositoryKey} from '../mt-repository/repository-key';
import {RepositoryType} from '../mt-repository/repository';
import {AuthUserInfo} from './auth-user-info';
import {MenuItemPermission} from './menu-item-permission';
import {Injectable} from '@angular/core';

@Injectable()
export class SecurityContext {

    private authUserInfo: AuthUserInfo;
    private username: string;

    constructor(private applicationRepository: ApplicationRepository) {
        this.authUserInfo = new AuthUserInfo();
    }

    isAuthenticated(): boolean {
        return this.getAccessToken() != null;
    }

    isRootUser() {
        return this.getUsername() === 'root';
    }

    clear() {
        this.authUserInfo.access_token = null;
        this.authUserInfo.token_type = null;
        this.applicationRepository.removeItem(RepositoryKey.USERNAME, RepositoryType.LOCAL);
        this.applicationRepository.removeItem(RepositoryKey.SPOOF_MODE, RepositoryType.LOCAL);
        this.applicationRepository.removeItem(RepositoryKey.CAN_SPOOF, RepositoryType.LOCAL);
        this.applicationRepository.removeItem(RepositoryKey.PERMISSIONS, RepositoryType.LOCAL);
        this.applicationRepository.removeItem(RepositoryKey.FIRST_LOGIN, RepositoryType.LOCAL);
        this.applicationRepository.removeItem(RepositoryKey.TOKEN, RepositoryType.LOCAL);
    }

    setAuthUserInfo(authUserInfo: AuthUserInfo, username: string) {
        this.authUserInfo.token_type = authUserInfo.token_type;
        this.setAccessToken(authUserInfo.access_token);
        this.setCanSpoofStatus(authUserInfo.can_spoof);
        this.setFirstLogin(authUserInfo.first_login);
        this.setAffiliateStatus(authUserInfo.is_affiliate);
        this.setPermissions(authUserInfo.permissions);
        this.setUsername(username);
    }

    getUsername(): string {
        if (this.username == null) {
            this.username = this.applicationRepository.getItem(RepositoryKey.USERNAME, RepositoryType.LOCAL);
        }
        return this.username;
    }

    setUsername(username: string) {
        this.applicationRepository.setItem(RepositoryKey.USERNAME, username, RepositoryType.LOCAL);
        this.username = username;
    }

    getAccessToken(): string {
        if (this.authUserInfo.access_token == null) {
            this.authUserInfo.access_token =
                JSON.parse(this.applicationRepository.getItem(RepositoryKey.TOKEN, RepositoryType.LOCAL));
        }
        return this.authUserInfo.access_token;
    }

    setAccessToken(accessToken: string) {
        this.applicationRepository.setItem(RepositoryKey.TOKEN, JSON.stringify(accessToken), RepositoryType.LOCAL);
        this.authUserInfo.access_token = accessToken;
    }

    getFirstLogin(): boolean {
        if (this.authUserInfo.first_login == null) {
            this.authUserInfo.first_login =
                JSON.parse(this.applicationRepository.getItem(RepositoryKey.FIRST_LOGIN, RepositoryType.LOCAL));
        }
        return this.authUserInfo.first_login;
    }

    setFirstLogin(value: boolean) {
        this.applicationRepository.setItem(RepositoryKey.FIRST_LOGIN, JSON.stringify(value), RepositoryType.LOCAL);
        this.authUserInfo.first_login = value;
    }

    getSpoofMode(): boolean {
        return JSON.parse(this.applicationRepository.getItem(RepositoryKey.SPOOF_MODE, RepositoryType.LOCAL)) ?? false;
    }

    setSpoofMode(value: boolean) {
        this.applicationRepository.setItem(RepositoryKey.SPOOF_MODE, JSON.stringify(value), RepositoryType.LOCAL);
    }

    getSpoofUser(): string {
        return JSON.parse(this.applicationRepository.getItem(RepositoryKey.SPOOF_USER, RepositoryType.LOCAL)) ?? '';
    }

    setSpoofUser(value: string) {
        this.applicationRepository.setItem(RepositoryKey.SPOOF_USER, JSON.stringify(value), RepositoryType.LOCAL);
    }

    getAffiliateStatus(): boolean {
        if (this.authUserInfo.is_affiliate == null) {
            this.authUserInfo.is_affiliate =
                JSON.parse(this.applicationRepository.getItem(RepositoryKey.AFFILIATE_STATUS, RepositoryType.LOCAL));
        }
        return this.authUserInfo.is_affiliate;
    }

    setAffiliateStatus(value: boolean) {
        this.applicationRepository.setItem(RepositoryKey.AFFILIATE_STATUS, JSON.stringify(value), RepositoryType.LOCAL);
        this.authUserInfo.is_affiliate = value;
    }

    getCanSpoofStatus(): boolean {
        if (this.authUserInfo.can_spoof == null) {
            this.authUserInfo.can_spoof =
                JSON.parse(this.applicationRepository.getItem(RepositoryKey.CAN_SPOOF, RepositoryType.LOCAL));
        }
        return this.authUserInfo.can_spoof;
    }

    setCanSpoofStatus(value: boolean) {
        this.applicationRepository.setItem(RepositoryKey.CAN_SPOOF, JSON.stringify(value), RepositoryType.LOCAL);
        this.authUserInfo.can_spoof = value;
    }

    getPermissions(): MenuItemPermission[] {
        if (this.authUserInfo.permissions == null) {
            this.authUserInfo.permissions =
                JSON.parse(this.applicationRepository.getItem(RepositoryKey.PERMISSIONS, RepositoryType.LOCAL));
        }
        return this.authUserInfo.permissions;
    }

    setPermissions(permissions: MenuItemPermission[]) {
        this.applicationRepository.setItem(RepositoryKey.PERMISSIONS, JSON.stringify(permissions), RepositoryType.LOCAL);
        this.authUserInfo.permissions = permissions;
    }
}
