import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../shared/service/api.service';
import {PostbackOfferRoutePageItem} from './postback-offer-route-page-item';
import {PostbackOfferRoute} from './postback-offer-route';

@Injectable()
export class PostbackOfferRouteService extends ApiService<PostbackOfferRoutePageItem, PostbackOfferRoute>{

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'postback_offer_route';
    }
}
