import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class BlockUiService {

    public blockUIEvent = new EventEmitter<boolean>(true);

    public startBlock() {
        this.blockUIEvent.emit(true);
    }

    public stopBlock() {
        this.blockUIEvent.emit(false);
    }

}
