import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SecurityContext} from './security-context';

@Injectable()
export class AnonymousGuardService implements CanActivate {

    constructor(private router: Router, private securityContext: SecurityContext) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.securityContext.isAuthenticated()) {
            this.router.navigate(['']);
            return false;
        }
        return true;
    }

}
